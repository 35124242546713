import { useState } from "react";
import DialerButton from "./buttons/dialer-button"
import { destinationsStore } from '../framework/stores/app-store';
import { useStore } from '@nanostores/react';
import { navigate } from "astro:transitions/client";
import { createCall } from "@root/services/calls-service";
import { callIdStore } from "@root/framework/stores/calls-store";

const Dialer = () => {

    const [dialCode, setDialCode] = useState("");
    const $callIds = useStore(destinationsStore);

    const handleClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        const button = e.currentTarget as HTMLButtonElement;
        if (button.name == "digit") {
            setDialCode(dialCode + button.innerText);
        }
        else if (button.name == "delete") {
            setDialCode(dialCode.slice(0, dialCode.length - 1));
        }
        else if (button.name == "call") {

            const areaId = $callIds.find(id => id.dialCode == dialCode)?.areaId;
            if (!areaId) {
                alert("No se encontró ningún departamento con ese número");
                return;
            };
            const callId = await createCall(areaId);
            callIdStore.set(callId);
            navigate(`/calls/${callId}`);
        }
    }

    return (
        <>
            <div className="border border-[#D1D0D0] rounded-lg flex w-full h-16">
                <input
                    type="number"
                    className="font-bold text-gray-800 text-xl bg-transparent flex-grow items-center text-center"
                    id="area-number"
                    maxLength={4}
                    placeholder="- - - -"
                    readOnly
                    value={dialCode}
                />
            </div>
            <div className="border border-[#D1D0D0] rounded-lg mt-6">
                <form
                    action="/api/calls"
                    method="post"
                    id="caller-form"
                    className="w-full h-full p-10"
                >
                    <div className="max-w-sm mx-auto text-center mb-3">
                        <p className="text-gray-800 font-semibold uppercase">
                            Ingresa número de departamento
                        </p>
                    </div>
                    <div className="w-full h-full border-t border-[#D1D0D0]">
                        <div
                            className="mt-4 grid grid-cols-3 gap-8 place-items-center text-gray-500 w-full h-full dark:text-gray-400"
                        >
                            <DialerButton text="1" handleClick={handleClick} />
                            <DialerButton text="2" handleClick={handleClick} />
                            <DialerButton text="3" handleClick={handleClick} />
                            <DialerButton text="4" handleClick={handleClick} />
                            <DialerButton text="5" handleClick={handleClick} />
                            <DialerButton text="6" handleClick={handleClick} />
                            <DialerButton text="7" handleClick={handleClick} />
                            <DialerButton text="8" handleClick={handleClick} />
                            <DialerButton text="9" handleClick={handleClick} />
                            <DialerButton text="x" name="delete" handleClick={handleClick} />
                            <DialerButton text="0" handleClick={handleClick} />
                            <DialerButton text="c" name="call" type="submit" handleClick={handleClick} />
                        </div>
                    </div>
                    <input type="hidden" id="hidden-area-number" name="area-number" />
                </form>
            </div>
        </>
    )
}

export default Dialer